import React from "react";

// Customizable Area Start
import { Grid, styled, Typography } from "@material-ui/core";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import { CopyRight, PhoneIcon, MailIcon } from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

import FooterController, { Props } from "./FooterController";

export default class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {feature_1, feature_2, feature_3, feature_4, feature_5, feature_6, address, phone_number, email, footer_content	} = this.state.footerContent;
    // Customizable Area End
    return (
      // Customizable Area Start
      <AboutUsFooter>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
            <div>
            <FooterText>{feature_1}</FooterText>
            <FooterText>{feature_2}</FooterText>
            <FooterText>{feature_3}</FooterText>
            </div>
          </Grid>
          <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
            <div>
            <FooterText>{feature_4}</FooterText>
            <FooterText>{feature_5}</FooterText>
            <FooterText>{feature_6}</FooterText>
            </div>
          </Grid>
          <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
            <div>
            <FooterTextRight style={{ width: "210px" }}>{address}</FooterTextRight>
            <FooterTextRight style={{ fontWeight: "700" }}><img src={PhoneIcon} alt="PhoneIcon" /> {phone_number}</FooterTextRight>
            <FooterTextRight data-test-id="email-btn" style={{ fontWeight: "700", cursor: 'pointer' }} onClick={this.SendEmail}><img src={MailIcon} alt="MailIcon" /> {email}</FooterTextRight>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <FooterDivider>
             <Typography style={{...CustomFontVariant.palette.font14400, lineHeight: "22px", marginBottom: "10px"}}>
               {footer_content}
             </Typography>
           </FooterDivider>
          </Grid>
        </Grid> 
      </AboutUsFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FooterText = styled(Typography)({
  fontSize: 18,
  fontWeight: 400,
  fontFamily: "Open Sans",
  lineHeight: "26px",
  marginBottom: "15px"
});

const FooterTextRight = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  fontFamily: "Open Sans",
  lineHeight: "24px",
  marginBottom: "15px",
  display: "flex",
  gap: "10px"
});

const FooterDivider = styled("div")({
  borderTop: `1px solid #0000004D`,
  width: "53%",
  height: "46px",
  position: "relative",
  top: 60,
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
});

const AboutUsFooter = styled("div")({
  padding: "70px 100px",
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: customTheme.palette.grey.primary,
  alignItems: "center",
});
// Customizable Area End
