import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleSubscribe?: () => void;
  isSubscribe?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isBilling: boolean;
  isPayment: boolean;
  isDeleteOpen: boolean;
  cardDetails: any[];
  isAddCard: boolean;
  showCVV: boolean;
  cardName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  isEdit: boolean;
  cardFormError: {
    cardName: string,
    cardNumber: string,
    expiryDate: string,
    cvv: string;
  };
  isPay: boolean;
  isView: boolean;
  planDetails: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPlansApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isBilling: false,
      isPayment: false,
      isDeleteOpen: false,
      cardDetails: [],
      isAddCard: false,
      showCVV: false,
      cardName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      isEdit: false,
      cardFormError: {
        cardName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
      },
      isPay: false,
      isView: false,
      planDetails: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getPlansApiCallID) {
        this.setState({planDetails: responseJson.data})
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.getPlansDetails();
    this.setState({cardDetails: [{
        cardNumber: "****8239"
    }]})
  }
  CardDetails = [
     {
            "id": 1,
            "title": "Premium",
            "feature_description": [
                "Get and ad free experience.",
                "Add years for unlimited properties.",
                "Search and View unlimited properties."
            ],
            "status": "Inactive",
            "created_at": "2024-12-18T12:29:35.147Z",
            "updated_at": "2024-12-18T15:12:58.662Z",
            "amount_cents": 10000
      },
      {
        "id": 2,
        "title": "Free",
        "feature_description": [
            "Get an ad free experience.",
            "Add years for up to 5 properties.",
            "Search and View unlimited properties."
        ],
        "status": "Active",
        "created_at": "2024-12-18T12:26:49.908Z",
        "updated_at": "2024-12-18T15:13:19.682Z",
        "amount_cents": 0
    },
  ]

  BillingTableHeader = [
    "Amount", "Type of Plan", "Payment date", "Card used to pay", ""
  ]

  BillingTableBody = [
    {
      amount: "1,080.00",
      plan: "Professional Plan",
      date: "Apr 14, 2022",
      card: "****8239",
      text: "Receipt"
    },
    {
      amount: "1,080.00",
      plan: "Professional Plan",
      date: "Apr 14, 2022",
      card: "****8239",
      text: "Receipt"
    },
    {
      amount: "1,080.00",
      plan: "Professional Plan",
      date: "Apr 14, 2022",
      card: "****8239",
      text: "Invoice"
    }
  ];

  validateDate = () => {
    let errorTxt = "";
    if (!this.state.expiryDate.trim()) {
      errorTxt = 'Expiry date is required.';
    } else if (!/^(0[1-9]|1[0-2])\/\d{0,2}$/.test(this.state.expiryDate)) {
      errorTxt = 'Expiry date is not valid.';
    } else {
      const [month, year] = this.state.expiryDate.split('/').map(Number);
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear() % 100; 
      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        errorTxt = 'Expiry date cannot be in the past.';
      }
    }

    return errorTxt;
  }

  validateForm = () => {
    const newErrors = { cardNumber: '', cardName: '', expiryDate: '', cvv: '' };

    if (!this.state.cardNumber.trim()) {
      newErrors.cardNumber = 'Card number is required.';
    } else if (!/^\d*$/.test(this.state.cardNumber)) {
      newErrors.cardNumber = 'Card number is invalid.';
    } else if (!/^\d{8,19}$/.test(this.state.cardNumber)) {
      newErrors.cardNumber = 'Card number should be between 8 and 19 digits.';
    }

    if (!this.state.cardName.trim()) {
      newErrors.cardName = 'Card name is required.';
    } else if (!/^[a-zA-Z\s]*$/.test(this.state.cardName)) {
      newErrors.cardName = 'Card name only contain alphabets.';
    }

    if (!this.state.cvv.trim()) {
      newErrors.cvv = 'CVV is required.';
    } else if (!/^\d*$/.test(this.state.cvv)) {
      newErrors.cvv = 'CVV must contain only digits.';
    } else if (!/^\d{3}$/.test(this.state.cvv)) {
      newErrors.cvv = 'CVV should be 3 digits.';
    }

    newErrors.expiryDate = this.validateDate()

    this.setState({cardFormError: newErrors})
    return !newErrors.cardNumber && !newErrors.cardName && !newErrors.expiryDate && !newErrors.cvv;
  };

  handleChange = (event: any, name: string) => {
    if(name === "expiryDate") {
      if (/^\d{2}$/.test(event.target.value)) {
        event.target.value = event.target.value + "/";
      }

    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
      cardFormError: {
        ...prevState.cardFormError, // Keep existing fields intact
        [name]: "", // Update the name field
      },
    }));
  };

  handleBackBtn = () => {
    this.setState({isBilling: false, isPayment: false})
  }

  deleteCardDetails = () => {
    this.setState({isDeleteOpen: false})
  };

  handleEdit = () => {
    this.setState({ isEdit: true, isView: false })
  };

  handleDelete = () => {
    this.setState({ isDeleteOpen: true })
  }

  handleAddCard = () => {
    const newErrors = { cardNumber: '', cardName: '', expiryDate: '', cvv: '' };
    this.setState({ isAddCard: true, cardFormError: newErrors })
  };

  handleView = () => {
    this.setState({isView: true, isEdit: false})
  }

  subscriptionApiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  getPlansDetails = async () => {
    this.getPlansApiCallID = await this.subscriptionApiCall({
      method: "GET",
      endPoint: `bx_block_subscription_billing/get_plans`,
      contentType: "application/json",
    });
  };
  // Customizable Area End
}
