import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  InputBase
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Header from "../../../blocks/landingpage/src/Header.web";
import customTheme from '../../../components/src/CustomTheme.web'; 
import ViewChat from "./ViewChat.web";
import CustomModal from "../../../components/src/CustomModal.web";
import { CircularProgress, styled } from "@material-ui/core";
import { createNewGroupIcon, searchIcon, profileImg } from './assets';
import CustomFontVariant from "../../../../packages/components/src/CustomFontVariant.web";
import { GroupMembersData, IChatAttributes, IGroupChatAttributes, ISearchFriendsList } from "./ChatController";
import Toast from "../../../components/src/CustomSnackbar.web";

const PlusIcon = require("../assets/plus_icon.png")

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddRoomModal = () => {
    return (
      <>
        {this.state.createChatModalOpen && (
        <CreateChatModal>
          <CreateChatModalContainer>
            <CreateChatTitle>
              Create chat
            </CreateChatTitle>
            <CreateChatButtonBox>
              <CreateGroupButton 
                onClick={() => this.handleOpenCreateGroupView()}
                data-test-id="openCreateGroupViewButton"
              >
                <CreateGroupIconBox>
                  <CreateGroupIcon src={createNewGroupIcon}></CreateGroupIcon>
                </CreateGroupIconBox>
                <CreateGroupTitle>New Group</CreateGroupTitle>
              </CreateGroupButton>
            </CreateChatButtonBox>
          
            <SearchFriendBox>
              <SearchIcon src={searchIcon} alt="" />
              <SearchInput 
                data-test-id="searchInput"
                placeholder="search friends and groups" 
                value={this.state.searchFriendInput}
                onChange={this.handleSearchInputChange}
              />
            </SearchFriendBox>
            
            <SearchList>
              {this.state.searchFriendsList.map(friend => (
                <SearchItem key={friend.id} data-test-id="searchFriendsItem">
                  <SearchItemBox>
                    {this.renderProfileImageSearchList(friend)}
                    <FriendProfileName>{friend.attributes.name}</FriendProfileName>
                  </SearchItemBox>
                  <ButtonContained onClick={() => this.handleMessageButton(friend)} data-test-id="navigateToChatButton">
                    Message
                  </ButtonContained>
                </SearchItem>
            ))}
            </SearchList>

            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <ButtonCancel
                variant="outlined"
                data-test-id="handleCreateChatCancel"
                onClick={this.handleCloseCreateChatModal}
              >
                Cancel
              </ButtonCancel>
            </Box>
          </CreateChatModalContainer>
        </CreateChatModal> 
      )}
      </>
    )
  }

  renderProfileImageSearchList = (friend: ISearchFriendsList) => {
    const { photo, group_image, name } = friend.attributes;
  
    let imageSrc;

    if (friend.type === "connection_chat") {
      imageSrc = photo;
    } else if (friend.type === "group_chat_list") {
      imageSrc = group_image;
    }

    return imageSrc ? (
      <FriendProfileImg 
        src={imageSrc} 
        alt={name || ""} 
        data-test-id={friend.type === "connection_chat" ? "friendPhoto" : "groupImage"} 
      />
    ) : (
      <Box sx={webStyles.avatarCircle} data-test-id="circleWithLetter">
        {name?.charAt(0).toUpperCase()}
      </Box>
    );
  };
  

  renderRoomList = () => {
    const { chatList, groupList, selectedOption } = this.state;

    const chatListToDisplay = selectedOption === 'group' ? groupList : chatList;

    return (
      <>
        {chatListToDisplay.map((item: IChat, index: number) => {
          if (item.type === 'chat_room') {
            return this.renderPrivateChat(item, index);
          } else if (item.type === 'group_chat_list') {
            return this.renderGroupChat(item, index);
          }
          return null;
      })}
      </>
    );
  };

  renderPrivateChat = (chat: IChat, index: number) => {
    const { receiverId } = this.state;

    const attributes = chat.attributes as IChatAttributes;

    return (
      <Box
        data-test-id={`btnShowChat${index}`}
        key={`room-${index}`}
        sx={{
            ...webStyles.listItemContainer,
          backgroundColor: receiverId == attributes.receiver_id ? '#EAE7DC' : 'transparent',
      }}
        onClick={() => this.handleChatSelection(chat)}
      >
        <Box sx={{ marginRight: '12px'}}>
          {attributes.photo ? (
            <img src={attributes.photo} alt={attributes.receiver_name} style={{ width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover' }} />
          ) : (
            <Box sx={webStyles.avatarCircle}>
              {attributes.receiver_name?.charAt(0).toUpperCase()}
            </Box>
          )}
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ fontWeight: '700', fontSize: '14px', lineHeight: '22px' }}>{attributes.receiver_name}</Typography>
            {attributes.unread_count > 0 && <Box sx={webStyles.blackDot} data-test-id="blackDot" />}
          </Box>

          <Typography style={{ color: '#0F172A', fontSize: '14px', lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '218px' }}>
            {attributes.last_message}
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ color: '#64748B', fontSize: '14px', lineHeight: '22px', marginBottom: '4px' }}>
            {attributes.last_message_time ? this.getLastTimeMessage(attributes.last_message_time) : ''}
          </Typography>
          <Box sx={{width: '20px', height: '20px',}}>
            {attributes.unread_count > 0 && (
              <Box sx={webStyles.unreadCount}>
                {attributes.unread_count}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  renderGroupChat = (chat: IChat, index: number) => {
    const { receiverId } = this.state;
    
    const attributes = chat.attributes as IGroupChatAttributes;
    const groupMembers = attributes.group_members;

    return (
      <Box
        data-test-id={`btnShowChat${index}`}
        key={`room-${index}`}
        sx={{
            ...webStyles.listItemContainer,
            backgroundColor: receiverId == attributes.id ? '#EAE7DC' : 'transparent',
      }}
        onClick={() => this.handleChatSelection(chat)}
      >
        <Box sx={{ marginRight: '12px'}}>
          {attributes.group_image ? (
            <img src={attributes.group_image} alt={attributes.name?.charAt(0).toUpperCase()} style={{ width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover' }} />
          ) : (
            <Box sx={webStyles.avatarCircle}>
              {attributes.name?.charAt(0).toUpperCase()}
            </Box>
          )}
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ fontWeight: '700', fontSize: '14px', lineHeight: '22px' }}>{attributes.name}</Typography>
            {attributes.last_message_data && attributes.last_message_data.unread_count > 0 && <Box sx={webStyles.blackDot} data-test-id="blackDot" />}
          </Box>

        
            <Typography style={{ color: '#0F172A', fontSize: '14px', lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '218px' }}>
              {attributes.last_message_data?.text && (
                <>
                  {attributes.last_message_data?.name}
                  <span style={{ color: '#64748B' }}>
                    : {attributes.last_message_data.text}
                  </span>
                </>
              )}
            </Typography>
          
        </Box>

        <Box>
          <Typography sx={{ color: '#64748B', fontSize: '14px', lineHeight: '22px', marginBottom: '4px' }}>
            {attributes.last_message_data && attributes.last_message_data.time ? this.getLastTimeMessage(attributes.last_message_data.time) : ''}
          </Typography>
          <Box sx={{width: '20px', height: '20px',}}>
            {attributes.last_message_data && attributes.last_message_data.unread_count > 0 && (
              <Box sx={webStyles.unreadCount}>
                {attributes.last_message_data.unread_count}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

renderMemberImageOrLetter = (member: any, style: any, positionStyle: any = {}) => {
  const { photo, name } = member.attributes;
  return photo ? (
    <img
      src={photo}
      alt={name}
      style={{ ...style, ...positionStyle, objectFit: 'cover' }}
      key={member.id}
    />
  ) : (
    <div style={{ ...webStyles.letterCircle, ...positionStyle }} data-test-id="friendCircleWithLetter">
      {name.charAt(0).toUpperCase()}
    </div>
  );
};

renderMultipleMembers = (members: any, style: any, positionStyles: any[] = []) => {
  return members.map((member: any, index: number) =>
    this.renderMemberImageOrLetter(member, style, positionStyles[index])
  );
};

createCollage = (groupMembers: GroupMembersData) => {
  const members = groupMembers.data;

  switch (members.length) {
    case 1: {
      const member = members[0];
      return this.renderMemberImageOrLetter(member, { width: '44px', height: '44px', borderRadius: '50%' });
    }
    case 2: {
      return (
        <Box sx={webStyles.doubleCollage} data-test-id="doubleCollage">
          {this.renderMultipleMembers(members, webStyles.doublePhoto, [
            { left: '0', position: 'absolute' },
            { left: '22px', position: 'absolute' }
          ])}
        </Box>
      );
    }
    case 3: {
      return (
        <Box sx={webStyles.tripleCollage} data-test-id="tripleCollage">
          <Box sx={webStyles.topPhoto}>
            {this.renderMemberImageOrLetter(members[0], webStyles.topSinglePhoto)}
          </Box>
          <Box sx={webStyles.bottomPhotos}>
            {this.renderMultipleMembers(members.slice(1), webStyles.bottomPhoto)}
          </Box>
        </Box>
      );
    }
    case 4: {
      return (
        <Box sx={webStyles.quadCollage} data-test-id="quadCollage">
          {this.renderMultipleMembers(members, webStyles.quadPhoto)}
        </Box>
      );
    }
    default:
      return null;
  }
};

renderLoader = () => {
  return(
  <div
    style={{
      margin:"10px",
    }}
  >
    <CircularProgress size={20} color={customTheme.palette.grey.primary} />
  </div>
  )
};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {loading}=this.state;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Box sx={{minHeight: '78px'}}>
              <Header activeTab="4" navigation={this.props.navigation} id={""} />
            </Box>

            <Box sx={webStyles.pageContainer}>
              <Box sx={webStyles.leftSideLayout}>
                  <Box sx={{display:"flex", justifyContent: "space-between", alignItems: "center", padding: "14px 24px", borderBottom:"1px solid #E2E8F0", marginBottom:"16px"}}>
                    <Typography style={{
                        fontWeight: 700,
                        fontSize: "30px",
                        lineHeight: "40px",
                        color: "#1E293B",
                        fontFamily: "Open Sans",
                      }}>Chat</Typography>
                    <Button
                      data-test-id={"createChatRoomBtn"}
                      variant="contained"
                      color="primary"
                      onClick={this.showCreateChatModal} 
                      style={{padding: "10px", backgroundColor: '#EAE7DC', borderRadius: '8px', minWidth: 'auto', textDecoration: 'none', boxShadow: 'none'}}>
                      <img src={PlusIcon} alt="Plus Icon" style={webStyles.image} />
                    </Button>
                  </Box>

                  <Box sx={{borderBottom:"1px solid #E2E8F0"}}>
                    <ToggleButtonGroup
                      data-test-id="handleOptionChangeButton"
                      value={this.state.selectedOption}
                      exclusive
                      onChange={this.handleOptionChange}
                      aria-label="chat options"
                      sx={webStyles.toggleButtonGroup}
                    >
                      <ToggleButton sx={webStyles.toggleButton} value="all" aria-label="all">
                        All
                      </ToggleButton>
                      <ToggleButton sx={webStyles.toggleButton} value="private" aria-label="private">
                        Private
                      </ToggleButton>
                      <ToggleButton sx={webStyles.toggleButton} value="group" aria-label="group">
                        Group
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                 {loading?this.renderLoader():
                  <Box sx={webStyles.roomListContainer}>{this.renderRoomList()}</Box>
                 }
                  
              </Box>
              {this.renderAddRoomModal()}

              <Box sx={{width:"100%", overflow: 'auto'}}>
                <ViewChat
                  senderChatUrl={this.state.senderChatUrl}
                  receiverId={this.state.receiverId}
                  accountId={this.state.accountId}
                  navigation={this.props.navigation} 
                  senderName={this.state.senderName}
                  receiverName={this.state.receiverName}
                  receiverPhoto={this.state.receiverPhoto}
                  lastMessage={this.state.lastMessage}
                  updateChatList={this.updateChatList}
                  chatType={this.state.chatType}
                  groupMembersCount={this.state.groupMembersCount}
                  isCreateGroupViewOpen={this.state.isCreateGroupViewOpen}
                  handleCloseCreateGroupView={this.handleCloseCreateGroupView}
                  groupFormData={this.state.groupFormData}
                  handleGroupFormChange={this.handleGroupFormChange}
                  searchFriendsListForGroup={this.state.searchFriendsListForGroup}
                  searchFriendForGroupInput={this.state.searchFriendForGroupInput}
                  handleSearchFriendsForGroupInputChange={this.handleSearchFriendsForGroupInputChange}
                  addMembersToTheGroup={this.addMembersToTheGroup}
                  handleCreateGroupChat={this.handleCreateGroupChat}
                  id={""}
                  viewContact={this.state.viewContact}
                  viewContactCallback={this.handleViewContact}
                  selectedChatId={this.state.selectedChatId}
                  isGroupInfoVisible={this.state.isGroupInfoVisible}
                  viewGroupCallback ={this.handleViewGroup}
                  groupDescription={this.state.groupDescription}
                  selectedGroupMember={this.state.selectedGroupMember}
                  handleChatSelection={this.handleChatSelection}
                  getChatList={this.getChatList}
                  receiverBio={this.state.receiverBio}
                  receiverEmail={this.state.receiverEmail}
                  mutualGroups={this.state.mutualGroups}
                  isBlocked={this.state.isBlocked}
                  correctionId={this.state.correctionId}
                  isGroupAdmin={this.state.isGroupAdmin}
                  handleConnectionId={this.handleConnectionId}
                />
              </Box>
            </Box>
            <Toast
            open={this.state.isToastOpen}
            message={this.state.chatToastMsg}
            severity={"success"}
            onClose={this.handleCloseChatToast}
            duration={3000}
            position={{ vertical: "top", horizontal: "center" }}
            data-test-id="handleCloseChatToast"
          />
          <Toast
            open={this.state.isToastError}
            message={this.state.chatToastMsg}
            severity={"error"}
            onClose={this.handleCloseChatToast}
            duration={3000}
            position={{ vertical: "top", horizontal: "center" }}
            data-test-id="handleErrorChatToast"
          />
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  pageContainer: {
    display: "flex",
    flexGrow: '1',
    maxWidth: "100%",
    padding: "0 40px",
    boxSizing: "border-box",
  },
  leftSideLayout: {
    maxWidth: "360px",
    width: "100%",
    height: "100%", 
    borderLeft: "1px solid #E2E8F0",
    borderRight: "1px solid #E2E8F0",
    padding: "6px 0px",
    backgroundColor: customTheme.palette.white.main,
    boxSizing: "border-box",
  },
  image: {
    width: "24px",
    height: "24px",
  },
  toggleButtonGroup: {
    borderRadius: '50px',
    backgroundColor: '#F8FAFC',
    padding: '4px',
    width: "fit-content",
    marginBottom: "16px",
  },
  toggleButton: {
    '&.MuiButtonBase-root': {
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#64748B',
      padding: '8px 12px',
      borderRadius: '50px',
      textTransform: 'none',
      '&.Mui-selected': {
        backgroundColor: '#EAE7DC',
        color: '#000',
      },
    },
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #E2E8F0",
    padding: "16px 24px",
    boxSizing: "border-box",
  },
  avatarCircle: {
    fontFamily: 'Open Sans',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    backgroundColor: '#EAE7DC99',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    border: '2px solid #CBD5E1',
  },
  blackDot: {
    width: '10px',
    height: '10px',
    backgroundColor: 'black',
    marginLeft: '6px',
    borderRadius: '50%',
  },
  unreadCount: {
    fontFamily: 'Open Sans',
    backgroundColor: '#EAE7DC',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  letterCircle: {
    width: '22px',
    height: '22px',
    backgroundColor: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: '#fff',
  },
  doubleCollage: {
    position: 'relative',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  doublePhoto: {
    position: 'absolute',
    width: '48%',
    height: '100%',
  },
  tripleCollage: {
    width: '44px',
    height: '44px',
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  topPhoto: {
    position: 'absolute',
    top: '0',
    left: '12px',
    width: '21px',
    height: '21px',
    objectFit: 'cover',
  },
  topSinglePhoto: {
    width: '21px',
    height: '21px',
  },
  bottomPhotos: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '0',
    width: '44px',
  },
  bottomPhoto: {
    width: '21px',
    height: '21px',
  },
  quadCollage: {
    display: 'grid',
    gridTemplateColumns: '22px 22px',
    gridGap: '1px',
    width: '44px',
    height: '44px',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  quadPhoto: {
    width: '22px',
    height: '22px',
  },
};

const CreateChatModal = styled("div")({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const CreateChatModalContainer = styled("div")({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "40px",
  borderRadius: "24px",
  margin: "100px auto",
  position: "relative",
});

const CreateChatTitle = styled("h3")({
  fontFamily: 'Open Sans',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 'bold',
  margin: 0,
  marginBottom: '30px',
});

const ButtonCancel = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  borderColor: `${customTheme.palette.black.primary}`,
  borderRadius: '8px',
});

const CreateChatButtonBox = styled("div")({
  padding: '24px 0',
  marginBottom: '30px',
  borderBottom: '1px solid #CBD5E1',
});

const CreateGroupButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const CreateGroupIconBox = styled("div")({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#EAE7DC',
  borderRadius: '50%',
  marginRight: '24px',
  padding: '10px',
});

const CreateGroupIcon = styled("img")({
  width: '24px',
  height: '24px'
});

const CreateGroupTitle = styled("h5")({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  textTransform: 'initial',
  color: 'black',
  margin: 0,
});

export const SearchFriendBox = styled("div")({
  display: 'flex',
  alignItems: 'center',
  border: '2px solid #EAE7DC',
  borderRadius: '30px',
  padding: '10px 8px',
  marginBottom: '30px',
});

export const SearchIcon = styled("img")({
  width: '24px',
  height: '24px',
  marginRight: '10px',
});

export const SearchInput = styled(InputBase)({
  fontFamily: 'Open Sans',
    padding: 0,
    width: '100%',
    '&::placeholder': {
      fontSize: '16px',
      color: '#94A3B8',
    },
});

export const SearchList = styled("div")({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '30px',
  maxHeight: '430px',
  overflowY: 'auto',
});

export const SearchItem = styled("div")({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 0',
  borderBottom: '1px solid #F1F5F9',
});

export const SearchItemBox = styled("div")({
  display: 'flex',
  alignItems: 'center',
});

export const FriendProfileImg = styled("img")({
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  objectFit: 'cover',
});

const FriendProfileName = styled("div")({
  ...CustomFontVariant.palette.font20400,
  paddingLeft: '16px',
});

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  borderRadius: '8px',
});
// Customizable Area End
