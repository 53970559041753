import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import {
  background,
  imageSearch,
  locationImage,
  logoImage,
  searchImage,
} from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import SearchController, { Props } from "./SearchController";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <Wrapper1>
        <BackGround1 />
        <MainContainer1>
          <Main1>
            <div style={{ textAlign: "center" }}>
              <img src={logoImage} alt="logo_image" />
            </div>

            <div className="location-search-wrapper">
              <MainDiv className="demo">
                <LoadScript
                  googleMapsApiKey="AIzaSyAo-9fUO2LylyU-zqQgGbdKPTzm7T0vyq4"
                  libraries={["places"]}
                >
                  <StandaloneSearchBox
                    onLoad={(ref) => (this.inputRef = ref)}
                    onPlacesChanged={this.handlePlaceChanged}
                    data-test-id="placeSearch"
                  >
                    <div
                      style={{ display: "flex", gap: "5px" }}
                      className="location-input"
                    >
                      <img src={searchImage} alt="searchIcon" />
                      <SearchInput
                        onChange={this.handleInputChange}
                        data-test-id="searchValue"
                        type="text"
                        placeholder="Search for the place..."
                      />
                    </div>
                  </StandaloneSearchBox>
                </LoadScript>
              </MainDiv>
            </div>

            {this.state.searchValue === "" &&
              this.state.suggestions.length < 1 && (
                <div style={{ padding: "0px 20px" }}>
                  <div style={{ textAlign: "center", margin: "10px 0px" }}>
                    <LocationTitle>Previously Searched Locations</LocationTitle>
                  </div>

                  {this.state.recentlySerchedData.map((item: any) => {
                    return (
                      <div
                        key={item}
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          borderBottom: "3px solid #F1F5F9",
                          margin: "20px 0px",
                          cursor: "pointer",
                        }}
                      >
                        <img src={locationImage} alt="locationIcon" />
                        <PreviousLocation
                          onClick={() =>
                            this.previousSearchRedirect(
                              item.latitude,
                              item.longitude,
                              item.address
                            )
                          }
                        >
                          {item?.address}
                        </PreviousLocation>
                      </div>
                    );
                  })}
                </div>
              )}

            {this.state.searchValue !== "" &&
              this.state.suggestions.length <= 0 && (
                <div style={{ textAlign: "center" }}>
                  <img src={imageSearch} alt="image_search" />
                  <NoResult>No search results !</NoResult>
                  <NoResultTitle>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod temporna aliqua.
                  </NoResultTitle>
                </div>
              )}
          </Main1>
        </MainContainer1>
      </Wrapper1>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper1 = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const BackGround1 = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  backgroundPosition: "center",
  zIndex: -1,
});

const MainContainer1 = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const Main1 = styled(Box)({
  padding: 40,
  backgroundColor: customTheme.palette.white.main,
  marginTop: "80px",
  marginBottom: "100px",
  maxWidth: "880px",
  minHeight: "450",
  borderRadius: 24,
  width: "100%",
});

const MainDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  borderRadius: "50px",
  padding: "10px",
  border: "1px solid #EAE7DC",
  margin: "50px 0px",
});

const SearchInput = styled("input")({
  border: "none",
  outline: "none",
  width: "800px",
  "&::placeholder": {
    ...CustomFontVariant.palette.font16400,
    color: customTheme.palette.grey.extraLight,
  },
});

const LocationTitle = styled("div")({
  ...CustomFontVariant.palette.font16700,
  color: customTheme.palette.grey.bold,
});

const PreviousLocation = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.main,
});

const NoResult = styled("div")({
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  color: customTheme.palette.black.primary,
  margin: "10px 0px",
});

const NoResultTitle = styled("div")({
  fontSize: "14px",
  fontWeight: 7400,
  lineHeight: "22px",
  color: customTheme.palette.black.primary,
  margin: "10px 0px",
});

// Customizable Area End
