import React from "react";

// Customizable Area Start
import { Grid, styled } from "@material-ui/core";
import Header from "../../../blocks/landingpage/src/Header.web";
import { aboutUs } from "./assets";
import Footer from "../../../blocks/landingpage/src/Footer.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {header_title, header_content, display_title, display_content, image} = this.state.contentData
    return (
      <>
        <Header
          activeTab="2"
          navigation={this.props.navigation}
          id={""}
          isAuthenticated={Boolean(this.state.isLoggedIn)}
        />
        <MissionContainer>
          <Grid container spacing={8}>
            <Grid item xs={12} md={4} style={{display: "flex", justifyContent: 'center', alignItems: "center"}}>
              <Heading>{header_title}</Heading>
            </Grid>
            <Grid item xs={12} md={7} >
              <SubHeading>
              {header_content}
              </SubHeading>
            </Grid>
          </Grid>
        </MissionContainer>
        <AboutUsContent>
          <Grid container spacing={8} style={{display: "flex", justifyContent: 'space-between'}}>
            <Grid item xs={12} md={5}>
              <Heading>
               {display_title}
              </Heading>
              <SubHeading style={webStyle.mt10}>
                {display_content}
              </SubHeading>
            </Grid>
            <Grid item xs={12} md={5} style={{padding: "0px !important"}}>
              <Img
                src={image}
                alt="image"
              />
            </Grid>
          </Grid>
          <DividerBlock>
            <Divider />
            <Divider />
          </DividerBlock>
        </AboutUsContent>
        <Footer navigation={this.props.navigation} id={""} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Divider = styled("div")({
  borderBottom: `1px solid #E2E8F0`,
  width: "50%"
});

const DividerBlock = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "70px"
})

const webStyle = {
  mt10: {
    marginTop: 10,
  },
};

const MissionContainer = styled("div")({
  padding: "70px 100px",
  backgroundColor: customTheme.palette.grey.primary,
  display: "flex", 
  alignItems: "center"
});

const AboutUsContent = styled("div")({
  padding: "70px 100px",
  display: 'flex',
  flexDirection: "column",
  gap: "75px"
});

const Heading = styled("div")({
  fontSize: 36,
  fontWeight: 700,
  fontFamily: "Open Sans",
  lineHeight: "44px",
});

const SubHeading = styled("div")({
  ...CustomFontVariant.palette.font24400,
  lineHeight: "32px",
});

const Img = styled("img")({
  width: "100%",
  borderRadius: 24,
  height: 346,
});
// Customizable Area End
