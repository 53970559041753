import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  footerContent: {
    feature_1: string,
    feature_2: string, 
    feature_3: string, 
    feature_4: string, 
    feature_5: string, 
    feature_6: string, 
    address: string, 
    phone_number: string, 
    email: string, 
    footer_content: string,
  }
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFooterContentApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      footerContent: {
       feature_1: "",
       feature_2: "", 
       feature_3: "", 
       feature_4: "", 
       feature_5: "", 
       feature_6: "", 
       address: "", 
       phone_number: "", 
       email: "", 
       footer_content: "",
      }
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getFooterContentApiCallID && responseJson.data) {
        this.setState({footerContent: responseJson.data.attributes})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getFooterContent()
  }
  SendEmail = async () => {
    const [baseUrl, queryString] = window.location.href.split("?");
    await this.generateEmail(baseUrl, new URLSearchParams(queryString));
  };

  generateEmail = async (baseUrl: string, params: URLSearchParams) => {
    const currentUrl = `${baseUrl}?${params.toString()}`;
    const subject = encodeURIComponent("Check out this link!");
    const bodyContent = encodeURIComponent(`Here is the link: ${currentUrl}`);
    const mailtoLink = `mailto:?subject=${subject}&body=${bodyContent}`;
    window.location.href = mailtoLink;
  };

  getFooterContent = () => {
    let token = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFooterContentApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFooterContentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}